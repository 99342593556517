// Generated by src/config/configureApp
// Do not modify or delete this file directly.
// Run npm configure:<environment> to generate
module.exports = {
  "API_CONFIG": [
    {
      "name": "evolytics-bball-predictor-api",
      "rootUrl": "https://ymvo18sqab.execute-api.us-east-1.amazonaws.com/production",
      "routes": [
        {
          "endpoint": "/matchups/:matchupId/predictions",
          "method": "GET",
          "name": "getMatchupPredictions"
        }
      ]
    },
    {
      "name": "elo",
      "rootUrl": "https://zwo4y9wbo3.execute-api.us-east-1.amazonaws.com/production",
      "routes": [
        {
          "endpoint": "/elo/prediction",
          "method": "POST",
          "name": "postEloPrediction"
        },
        {
          "endpoint": "/elo/prediction/batch",
          "method": "POST",
          "name": "postBatchEloPrediction"
        }
      ]
    },
    {
      "name": "scheduled-prediction-service",
      "rootUrl": "https://idhzhd2c52.execute-api.us-east-1.amazonaws.com/production",
      "routes": [
        {
          "endpoint": "/v0/upcoming-games",
          "method": "GET",
          "name": "getUpcomingGamePredictions"
        }
      ]
    },
    {
      "name": "multi-model-service",
      "rootUrl": "https://1uhuqdfnqf.execute-api.us-east-1.amazonaws.com/production",
      "routes": [
        {
          "endpoint": "/v0/predictions",
          "method": "POST",
          "name": "postMultiModelPredictions"
        }
      ]
    }
  ],
  "ENV": "production",
  "FEATURE_FLAGS": {
    "@@UnitTesting": true,
    "MultiModelMatchupPredictor": false,
    "MultiModelEloEnabled": false,
    "ShowBlogSection": true,
    "SPSTournament": false,
    "TableauVizathonBanner": false
  },
  "MULTI_MODELS": [
    "gradient",
    "logistic-regression",
    "mlp"
  ],
  "GA_PATH_PREFIX": "/labs",
  "MAILCHIMP_FORM_ACTION": "https://evolytics.us11.list-manage.com/subscribe/post?u=3afb439da16c54c8a6db8fbad&id=85de6d5783"
}