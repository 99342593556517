import {ENV} from '../config';

class Analytics {
  constructor(gtag) {
    this.gtag = gtag;
  }

  track(type, action, params = {}) {
    if (!this.gtag) return;

    try {
      if (type === 'event') {
        this.gtag('event', action, params);
      }
    } catch (error) {
      if (ENV !== 'production') console.error(error);
    }
  }

  trackExitLink(href, redirect) {
    this.track('event', 'click', {
      event_category: 'Exit Links',
      event_label: href,
      transport_type: 'beacon',
      event_callback: function() {
        if (redirect) {
          document.location = href;
        }
      }
    });
  }

  trackDownload(label) {
    this.track('event', 'click', {
      event_category: 'Download',
      event_label: label.toLowerCase()
    });
  }

  trackCTAClick(label) {
    this.track('event', 'click', {
      event_category: 'CTA Buttons',
      event_label: label.toLowerCase()
    });
  }

  trackModelEvent(model, action, label) {
    this.track('event', action, {
      event_category: model,
      event_label: label
    });
  }

  trackEvoLink(label) {
    this.track('event', 'click', {
      event_category: 'Labs Evo Link',
      event_label: label
    });
  }
}

let analytics;
Analytics.isCreated = false;

export default function createAnalytics() {
  if (Analytics.isCreated) return analytics;

  // A hack
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function() {
      window.dataLayer.push(arguments);
    };

    analytics = new Analytics(window.gtag);
    Analytics.isCreated = true;
    return analytics;
  }
};

