import React from 'react';
import {Helmet} from 'react-helmet';
import {GA_PATH_PREFIX} from '../config';

const SiteMetadata = () => {
  return (
    <Helmet>
      <html lang='en' />
      <meta
        name='description'
        content='Hoop-A-Nator will help you create your NCAA basketball bracket for tournament games. The machine learning predictive analytics used for basketball game score predictions use regular season data to predict basketball games in March and April.'
      />
      <title>Hoop-A-Nator | Evolytics</title>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-3862592-2"></script>
      <script type='text/javascript'>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-3862592-2', {
          'page_path': '${GA_PATH_PREFIX}/college-bball-game-predictor'
        });
      `}</script>
    </Helmet>
  );
};

export default SiteMetadata;

