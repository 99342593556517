import React from 'react';
import {Icon} from 'antd';
import ExitLink from './ExitLink';

const SocialLinks = () => {
  return (
    <div className='social-links'>
      <ExitLink href='https://twitter.com/Evolytics'>
        <Icon type='twitter' />
      </ExitLink>

      <ExitLink href='https://www.linkedin.com/company/evolytics'>
        <Icon type='linkedin' />
      </ExitLink>
    </div>
  );
};

export default SocialLinks;

