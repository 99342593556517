import React from 'react';
import {Col, Layout, Row} from 'antd';
import SocialLinks from './SocialLinks';

import './Footer.less';

const Footer = () => {
  return (
    <Layout.Footer className='footer'>
      <Row gutter={16}>
        <Col span={3} />

        <Col md={6} xs={24}>
          <h3>Our Mission</h3>

          <p>
            Evolytics is evolving analytics by inspiring people 
            to use data in ways that make a difference in the world.
          </p>
        </Col>

        <Col md={6} xs={24}>
          <h3>Contact Us</h3>

          <p>
            1 W 1st Street Parkville, MO, 64152
            <br/>
            816-778-7300
            <br/>
            <a href='mailto:contact@evolytics.com'>
              contact@evolytics.com
            </a>
          </p>

        </Col>

        <Col md={6} xs={24}>
          <h3>Connect With Us</h3>

          <SocialLinks />
        </Col>

        <Col span={3} />
      </Row>

      <Row type='flex' justify='center'>
        <p style={{marginTop: '24px'}}>
          NCAA is a trademark of the National Collegiate Athletic Association
        </p>
      </Row>
    </Layout.Footer>
  );
};

export default Footer;

