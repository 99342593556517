import React from 'react';
import {Layout, Button} from 'antd';
import EvolyticsLogo from '../assets/images/evolytics-logo.png';
import Analytics from '../analytics';
import './Header.less';

const AntHeader = Layout.Header;
const analytics = Analytics();

const Header = () => (
  <AntHeader className='header'>
    <div className='logo'>
      <a href='https://www.evolytics.com'>
        <img
          src={EvolyticsLogo}
          alt='Evolytics Company Logo'
        />
      </a>
    </div>

    <div className='header-nav'>
      <a href='https://www.evolytics.com/contact/'>
        <Button
          type='primary'
          onMouseDown={e => analytics.trackCTAClick('contact us')}
        >
          Contact Us
        </Button>
      </a>
    </div>
  </AntHeader>
);

export default Header;

