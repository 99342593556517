import React from 'react';
import Analytics from '../analytics';

const analytics = Analytics();

// Modified gatsby-plugin-google-analytics Outbound link
const ExitLink = (props) => {
  return (
    <a
      {...props}
      href={props.href}
      onClick={e => {
        if (typeof props.onClick === 'function') {
          props.onClick(e);
        }

        let redirect = true;

        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false;
        }

        if (props.target && props.target.toLowerCase() !== '_self') {
          redirect = false;
        }

        if (analytics && typeof analytics.trackExitLink === 'function') {
          analytics.trackExitLink(props.href, redirect);
          return false;
        }

        if (redirect) {
          document.location = props.href;
        }

        return false;
      }}
    >
     {props.children}
    </a>
  );
};

export default ExitLink;

