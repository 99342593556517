import React from 'react';
import {Layout} from 'antd';
import SiteMetadata from './SiteMetadata';
import Header from './Header';
import Footer from './Footer';
import Banner from './banners/Banner';

const {Content} = Layout;

const DefaultLayout = (props) => {
  const {
    bannerText = '',
    bannerCta = null,
    children
  } = props;

  return (
    <div>
      <SiteMetadata />

      <Layout>
        {bannerText && (
          <Banner text={bannerText} cta={bannerCta} />
        )}

        <Header />

        <Content>
          {children}
        </Content>

        <Footer />
      </Layout>
    </div>
  );
};

export default DefaultLayout;

