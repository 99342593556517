import React from 'react';
import {Layout} from 'antd';
import './Banner.less';

const Header = Layout.Header;

const Banner = (props) => {
  const {
    text,
    cta
  } = props;

  return (
    <Header className='banner'>
      {text && (
        <div className='banner--text'>
          {text}
        </div>
      )}

      {cta && (
        <div className='banner--cta'>
          {cta()}
        </div>
      )}
    </Header>
  );
};

export default Banner;

